<template>
  <Transition v-bind="$attrs">
    <slot />
  </Transition>
</template>

<style lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-left-in-right-out-enter-active,
.slide-left-in-right-out-leave-active,
.slide-right-in-left-out-enter-active,
.slide-right-in-left-out-leave-active,
.slide-bottom-in-top-out-enter-active,
.slide-bottom-in-top-out-leave-active,
.slide-top-in-bottom-out-enter-active,
.slide-top-in-bottom-out-leave-active {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.slide-left-in-right-out-enter-active,
.slide-left-in-right-out-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.slide-right-in-left-out-enter-active,
.slide-right-in-left-out-leave-to {
  transform: translate3d(100%, 0, 0);
}

.slide-left-in-right-out-enter-to,
.slide-left-in-right-out-leave-from,
.slide-right-in-left-out-enter-to,
.slide-right-in-left-out-leave-from,
.slide-bottom-in-top-out-enter-to,
.slide-top-in-bottom-out-leave-from,
.slide-top-in-bottom-out-enter-to,
.slide-top-in-bottom-out-leave-from {
  transform: translate3d(0, 0, 0);
}

.slide-bottom-in-top-out-enter-from,
.slide-bottom-in-top-out-leave-to {
  transform: translate3d(0, -100%, 0);
}

.slide-top-in-bottom-out-enter-from,
.slide-top-in-bottom-out-leave-to {
  transform: translate3d(0, 100%, 0);
}

.slide-in-enter-active {
  animation: slide-in 0.3s;
}
.slide-in-leave-active {
  animation: slide-in 0.3s reverse;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.collapse-enter-active {
  overflow: visible;
  animation: collapse reverse 500ms ease;
}
.collapse-leave-active {
  overflow: hidden;
  animation: collapse 500ms ease;
}
@keyframes collapse {
  from {
    opacity: 1;
    max-height: 1500px;
  }
  to {
    opacity: 0;
    max-height: 0px;
  }
}
.fade-slide-enter-active {
  transition: transform 1s ease-out;
}

.fade-slide-leave-active {
  transition: opacity 0.8s ease-out;
}

.fade-slide-enter-from {
  transform: translateX(100%);
}
.fade-slide-enter-to {
  transform: translateX(0);
}
.fade-slide-leave-from {
  opacity: 1;
}
.fade-slide-leave-to {
  opacity: 0;
}
</style>
